import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonSkin, ButtonSize } from '@voyagers/button';

import RoutePath from 'js/app/RoutePath';

const GeneratePCBCalculation = () => {
    const { t } = useTranslation();

    return (
        <div className="swv-frame-layout">
            <div className="swv-content-container">
                <div className="swv-container container-fluid">
                    <div className="header-container">
                        <div className="container-fluid">
                            <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                <div className="logo-img _swingvy_logo_img" />
                            </a>
                        </div>
                    </div>
                    <div className="main-container _main_container">
                        <div className="row main-inner-container">
                            <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                <div className="content-inner-box forgot-password">
                                    <h2 className="title">{t('Ooops!')}</h2>
                                    <br />
                                    <div className="sub-title">
                                        {t('generate_pcb_calculation.content')}
                                    </div>
                                    <Button
                                        size={ButtonSize.Medium}
                                        skin={ButtonSkin.Primary}
                                        onClick={() => {
                                            window.close();
                                        }}
                                    >
                                        {t('common.close')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-container">
                        <div className="container">
                            <div className="link-item">
                                <a
                                    href="https://www.swingvy.com/en/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('intro_footer.privacy.link')}
                                </a>
                            </div>
                            <div className="link-item">
                                <a
                                    href="https://www.swingvy.com/en/tos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('intro_footer.terms.link')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneratePCBCalculation;
