import React from 'react';
import AbsView from 'js/components/common/AbsView';

class AbsPage extends AbsView {
    constructor(props) {
        super(props);
    }
}

export default AbsPage;
